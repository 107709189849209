import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import MiniBand from "../../../components/mini-band/mini-band";
import CheckingOverview from "../../../components/checking-overview/checking-overview";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import List from "../../../components/custom-widgets/list";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";

import WaFdMobileApp from "../../../images/wafd-mobile-app.gif";

const CheckingAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "checking-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman on her mobile phone while sitting cross-legged on a couch.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Interest, Rewards, and More, All in One Account"
          }
        },
        {
          id: 2,
          button: {
            id: "checking-account-hero-instant-open-button",
            text: "Open an Account",
            url: "#checking-overview-section",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "Compare & Open a Checking Account Online",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Compare & Open a Checking Account Online"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Open a checking account online with WaFd Bank. Compare checking accounts and find out the perks and benefits of free checking, rewards and interest checking."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/checking-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-checking-030524.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Checking Accounts"
    }
  ];

  const onlineMobileBankingListItems = {
    accountName: "online-mobile-banking-list",
    items: [
      {
        id: 1,
        text: "Pay bills online or with your smartphone"
      },
      {
        id: 2,
        text: "Check your balance, view transactions and transfer money"
      },
      {
        id: 3,
        text: "Deposit checks with your smart phone and mobile photo deposit"
      },
      {
        id: 4,
        text: "Pay friends with person-to-person transfers"
      },
      {
        id: 5,
        text: "Make online wire transfers"
      },
      {
        id: 6,
        text: "Reach your financial goals by setting budgets, managing spending, and tracking savings"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Checking Accounts</h1>
        <div className="row justify-content-between row">
          <div className="col-md-7 mb-3 mb-md-0">
            <h2>Get Access to Thousands of Fee Free ATMs with a Best Bank</h2>
            <p>
              Whether you're looking for Free Checking or an account with rewards and interest, we have accounts that
              are tailored to meet your needs. Let us show you why WaFd Bank has been voted Best Bank!
            </p>
            <Link
              to="/business-banking/business-checking-account"
              id="business-checking-page-link"
              className="font-weight-bold text-decoration-none"
            >
              Looking for Business Checking
              <Icon name="arrow-right" class="ml-1" />
            </Link>
          </div>
          <div className="col-md-5 col-lg-4">
            <Link to="/about-us/best-bank" id="clickable-best-banks-image" className="stretched-link">
              <StaticImage
                src="../../../images/newsweek-3yr-lockup-600.png"
                alt="WaFd Bank America's Best Bank 3 years in a row by Newsweek."
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </Link>
          </div>
        </div>
      </section>

      <CheckingOverview />
      <MiniBand sectionClass="bg-white py-0" />
      <section className="bg-blue-10">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <StaticImage
                src="../../../images/thumbnail-fresh-start-03312023-730.jpg"
                alt="Happy couple dancing together in the kitchen"
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
            <div className="col-md-6">
              <h3 className="font-weight-bold">Can't open a traditional bank account?</h3>
              <p>
                Start fresh with WaFd Bank's second chance bank account. If you've been denied a bank account, our Fresh
                Start account can help you get back on track.
              </p>
              <div>
                <Link
                  id="fresh-start-page-link"
                  to="/personal-banking/fresh-start-second-chance-account"
                  className="btn btn-primary w-100 w-sm-auto"
                >
                  Learn about Fresh Start Account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Online Banking */}
      <section className="container">
        <div className="row">
          <div className="col-lg-5 mb-3 mb-lg-0">
            <h3 className="text-success font-weight-semi-bold">Convenient and Secure Online Banking</h3>
            <List {...onlineMobileBankingListItems} />
            <Link
              to="/personal-banking/online-banking"
              id="online-banking-page-link"
              className="btn btn-link w-100 w-sm-auto"
            >
              WaFd Online Banking and Mobile App
            </Link>
          </div>
          <div className="col-lg-7 row">
            <div className="col-md-6 d-none d-md-block">
              <img src={WaFdMobileApp} className="w-100 w-sm-50 w-md-100" alt="WaFd Bank Mobile App home screen" />
            </div>
            <div className="col-md-6">
              <p className="text-success font-weight-bold text-sm">Get the WaFd Bank mobile app</p>
              <div className="row">
                <div className="col-6 col-md-auto col-lg-6 d-none d-md-block">
                  <StaticImage
                    src="../../../images/wafd-mobile-app-qr-code.png"
                    alt="WaFd App QR Code"
                    placeholder="blurred"
                    quality="100"
                    className="mb-3"
                  />
                  <p className="text-gray-60 text-nowrap text-sm">Scan to download</p>
                </div>
                <div className="col-sm-6 d-flex flex-row flex-md-column">
                  <div className="mr-4 mr-md-0">
                    <MobileAppDownloadBtn
                      idName="apple-store"
                      storeBtn="apple"
                      url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                    />
                    <p className="text-gray-60 text-nowrap text-sm">For Apple devices</p>
                  </div>
                  <div className="">
                    <MobileAppDownloadBtn
                      idName="google-play-store"
                      storeBtn="google"
                      url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                    />
                    <p className="text-gray-60 text-nowrap text-sm">For Android devices</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactInfo />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CheckingAccount;
